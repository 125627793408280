import React, { useState, useEffect, useContext } from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Configuration, OpenAIApi } from "openai";
import { useNavigate } from "react-router-dom";
import axios from '../api/axios';
import AuthContext from "../context/AuthProvider";
import "./InterviewPrep.css";
import VoiceToTextIcon from '../assets/texttovoice4.png';
import { MyAlertMessage } from '../components/MyAlert.jsx';
import { storeActivity } from '../functions/storeActivity.jsx';

const ChatGPTAPIKey = process.env.REACT_APP_GPT_API_KEY;
const BrainGeniusAPIKey = process.env.REACT_APP_GENIUS_BRAIN_API_KEY;
const configuration = new Configuration({ apiKey: ChatGPTAPIKey });
const openai = new OpenAIApi(configuration);

const InterviewPrep = () => {
  const [jobTitle, setJobTitle] = useState("");
  const [question, setQuestion] = useState("");
  const [feedback, setFeedback] = useState("");
  const [answer, setAnswer] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [newQuestion, setNewQuestion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [freeTrialUsedUp, setFreeTrialUsedUp] = useState(false);

  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const { transcript, resetTranscript } = useSpeechRecognition();

  const [open, setOpen] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  // Track page landing
  useEffect(() => {
    storeActivity(auth.sessionId, "Page Landing", "InterviewPrep", "Page", "Landed on InterviewPrep page");
  }, [auth.sessionId]);

  useEffect(() => {
    const checkSubscriptionAndFreeUses = async () => {
      if (auth && ["BASIC PLAN", "ADVANTAGE PLAN", "ELITE PLAN"].includes(auth.user_role_type)) {
        setIsSubscribed(true);
        storeActivity(auth.sessionId, "Subscription Check", "InterviewPrep", "Subscription", "User is subscribed");
      }
    };
    checkSubscriptionAndFreeUses();
  }, [auth]);

  useEffect(() => {
    if (transcript) {
      setAnswer(transcript);
    }
  }, [transcript]);

  const generateQuestion = async () => {
    if (!isSubscribed && freeTrialUsedUp) {
      handleOpenModal();
      return;
    }

    if (!jobTitle) {
      alert("Please enter the job title.");
      return;
    }

    try {
      if (!isSubscribed) {
        const response = await axios.post('/use-tool', {
          sessionId: auth.sessionId,
          toolId: 1,
          toolDesc: 'Interview preparation tool'
        }, {
          headers: { 'Content-Type': 'application/json' }
        });

        if (response.status === 403) {
          setFreeTrialUsedUp(true);
          handleOpenModal();
          return;
        }
      }

      const prompt = `Generate an interview question for the role of ${jobTitle}.`;
      const openAIResponse = await openai.createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages: [{ "role": "user", "content": prompt }]
      });

      const generatedQuestion = openAIResponse.data.choices[0].message.content.trim();
      setQuestion(generatedQuestion);
      setAnswer("");
      setFeedback("");
      setHasSubmitted(false);
      setNewQuestion(false);
      //speakQuestion(generatedQuestion);
      storeActivity(auth.sessionId, "Generate Question", "InterviewPrep", "Tool", `Generated question for job title: ${jobTitle}`);
    } catch (error) {
      if (error.response.status === 403) {
        alert('You have run out of free uses. Please subscribe for unlimited use.');
        setFreeTrialUsedUp(true);
        handleOpenModal();
      } else {
        console.error("Error generating question:", error);
        alert("There was an issue generating the question. Please try again.");
      }
    }
  };

  const speakQuestion = (question) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(question);
      window.speechSynthesis.speak(utterance);
    } else {
      alert("Sorry, your browser does not support text-to-speech.");
    }
  };

  const startListening = () => {
    setIsListening(true);
    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListening = async () => {
    setIsListening(false);
    SpeechRecognition.stopListening();
    setHasSubmitted(true);
    provideFeedback(question, answer);
    resetTranscript();
    storeActivity(auth.sessionId, "Submit Answer", "InterviewPrep", "Answer", `Submitted answer: ${answer}`);
  };

  const provideFeedback = async (question, answer) => {
    setLoading(true);
    try {
      const prompt = `Provide feedback for the following interview question and answer.\n\nQuestion: ${question}\n\nAnswer: ${answer}`;
      const openAIResponse = await openai.createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages: [{ "role": "user", "content": prompt }]
      });

      const feedbackText = openAIResponse.data.choices[0].message.content.trim();
      setFeedback(feedbackText);
      setNewQuestion(true);
      setLoading(false);
      storeActivity(auth.sessionId, "Generate Feedback", "InterviewPrep", "Tool", "Generated feedback for the answer");
    } catch (error) {
      console.error("Error generating feedback:", error);
      alert("There was an issue providing feedback. Please try again.");
      setLoading(false);
    }
  };

  const retryQuestion = () => {
    setAnswer("");
    setFeedback("");
    setHasSubmitted(false);
    resetTranscript();
    generateQuestion();
    storeActivity(auth.sessionId, "Retry Question", "InterviewPrep", "Tool", "Retried question");
  };

  const handleNewQuestion = () => {
    setQuestion("");
    setAnswer("");
    setFeedback("");
    setHasSubmitted(false);
    setNewQuestion(false);
    generateQuestion();
    storeActivity(auth.sessionId, "New Question", "InterviewPrep", "Tool", "Generated a new question");
  };

  return (
    <div className="interview-prep-container" style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1 style={{ textAlign: "center", fontWeight: "bold", fontSize: "28px" }}>AI Interview Prep</h1>

      <div className="instructions-box" style={{ backgroundColor: "#f8f9fa", padding: "20px", borderRadius: "8px", marginBottom: "20px", textAlign: "center" }}>
        <h3 style={{ fontWeight: "bold", color: "#343a40" }}>How to Use:</h3>
        <ol style={{ textAlign: "left", lineHeight: "1.7" }}>
          <li><strong>Enter Job Title:</strong> Type the job title for which you want to practice.</li>
          <li><strong>Generate Question:</strong> Click the button to generate a question.</li>
          <li><strong>Answer:</strong> Use voice-to-text or type your answer.</li>
          <li><strong>Submit:</strong> Submit your answer and get feedback.</li>
          <li><strong>Retry/New Question:</strong> You can retry or ask for a new question after feedback.</li>
          <li><strong>Important:</strong> Click "Start Answering" to record your answer.</li>
        </ol>
      </div>

      <div className="job-title-input" style={{ marginBottom: "20px", textAlign: "center" }}>
        <label style={{ fontSize: "1.2em", marginRight: "10px" }}>Enter the Job Title:</label>
        <input
          type="text"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          style={{
            padding: "10px",
            width: "300px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            fontSize: "1em"
          }}
        />
        <button
          onClick={generateQuestion}
          style={{
            marginLeft: "15px",
            padding: "10px 20px",
            fontSize: "1em",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background-color 0.3s ease"
          }}
          onMouseOver={(e) => e.target.style.backgroundColor = "#0056b3"}
          onMouseOut={(e) => e.target.style.backgroundColor = "#007BFF"}
        >
          Generate Interview Question
        </button>
      </div>

      {question && (
        <div style={{ marginBottom: "20px", textAlign: "center" }}>
          <div style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)"
          }}>
            <h3 style={{ color: "#343a40" }}>Question: <span style={{ fontWeight: "400", fontSize: "1.1em" }}>{question}</span></h3>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img
                src={VoiceToTextIcon}
                alt="Read Question"
                title="Read Aloud"
                style={{
                  cursor: "pointer",
                  width: "40px",
                  marginTop: "10px",
                  padding: "5px",
                  border: "1px solid #007BFF",
                  borderRadius: "50%",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"
                }}
                onClick={() => speakQuestion(question)}
              />
              <p style={{ marginLeft: "10px", fontWeight: "bold", color: "#007BFF" }}>Read Aloud</p>
            </div>

            {!hasSubmitted && (
              <button
                onClick={isListening ? stopListening : startListening}
                disabled={hasSubmitted}
                style={{
                  padding: "10px",
                  backgroundColor: isListening ? "#dc3545" : "#28a745",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginTop: "10px",
                  transition: "background-color 0.3s ease"
                }}
              >
                {isListening ? "Stop & Submit Answer" : "Start Answering"}
              </button>
            )}
            <p>Answer: {answer}</p>
          </div>
        </div>
      )}

      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <div className="spinner"></div>
          <p>Generating feedback...</p>
        </div>
      ) : (
        feedback && (
          <div style={{ padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "10px", textAlign: "center" }}>
            <h3 style={{ fontWeight: "bold", color: "#007BFF" }}>Feedback on Your Answer:</h3>
            <p>{feedback}</p>
          </div>
        )
      )}

      {newQuestion && (
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <button
            onClick={retryQuestion}
            style={{
              marginRight: "10px",
              padding: "10px 20px",
              backgroundColor: "#ffc107",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              transition: "background-color 0.3s ease"
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = "#e0a800"}
            onMouseOut={(e) => e.target.style.backgroundColor = "#ffc107"}
          >
            Retry Question
          </button>
          <button
            onClick={handleNewQuestion}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              transition: "background-color 0.3s ease"
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = "#0056b3"}
            onMouseOut={(e) => e.target.style.backgroundColor = "#007BFF"}
          >
            Next Interview Question
          </button>
        </div>
      )}

      <MyAlertMessage open={open} setOpen={setOpen} handleCloseModal={handleCloseModal} />
    </div>
  );
};

export default InterviewPrep;
