import React, { useState,useEffect,useContext } from 'react';
import { PayPalScriptProvider,PayPalButtons } from "@paypal/react-paypal-js";
import emailjs from 'emailjs-com';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import axios from './api/axios';
import AuthContext from "./context/AuthProvider";
import storeLocalCredits from './functions/storeLocalCredits.jsx';
import { retrieveLocalCredits } from './functions/retrieveLocalCredits';
import { incrementLocalCredits as increment } from './functions/incrementLocalCredits.jsx';
import { decrementLocalCredits as decrement } from './functions/decrementLocalCredits.jsx';
import {MyAlert,MyAlertMessage, MyAlertDocument} from './components/MyAlert.jsx';
import { Container } from 'react-bootstrap';
import "./CoverLetterBuilder.css";
import { FaSpinner } from 'react-icons/fa';
import storeActivity from './functions/storeActivity.jsx';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'


const ChatGPTAPIKey = process.env.REACT_APP_GPT_API_KEY;
//const ChatGPTAPIKey = "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";
const payPalClientId = process.env.REACT_APP_PAY_PAL_API_KEY
const emailjsAPIKEY= process.env.REACT_APP_EMAILJS_API_KEY
//const payPalClientId = "AXBVlA5Dt_5CSlM_qtiipa3Ubbp7c90ZkM3iqpdeGAglh5RT9KGFWxgtM7aNktw9Rp_lNc-RmGpWsWdo"



const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
  apiKey: ChatGPTAPIKey,
});

const openai = new OpenAIApi(configuration);

const CoverLetterBuilder = () => {
  const { auth,setAuth } = useContext(AuthContext);
  const [resume, setResume] = useState('');
  const [jobPosition, setJobPosition] = useState('');
  const [coverLetter, setCoverLetter] = useState('');
  const [qualifications, setQualifications] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [credits, setCredits] = useState(0);
  const [loggedIn, setLoggedIn] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [email, setEmail] = useState('');
  const [shareCoverLetter, setShareCoverLetter] = useState(true); //set the state of checkbox
  const [localCredits, setLocalCredits] = useState(0);
  const [triggerBuyButtonAlert, setTriggerBuyButtonAlert] = useState(false);
  const [triggerMyAlertMessage, setTriggerMyAlertMessage] = useState(false);


  const [open, setOpen] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const [documentOpen, setDocumentOpen] = useState(false);
  const handleDocumentOpenModal = () => setDocumentOpen(true);
  const handleDocumentCloseModal = () => setDocumentOpen(false);


  const tokenLimit = 1600;
  const resumeCharactersPerToken = 4;
  const resumeCharacterLimit = tokenLimit * resumeCharactersPerToken;

  const [resumeCharacters, setResumeCharacters] = useState(0);


  const qualificationsTokenLimit = 300;
  const qualificationsCharactersPerToken = 4;
  const qualificationsCharacterLimit = qualificationsTokenLimit * qualificationsCharactersPerToken;

  const [qualificationsCharacters, setQualificationsCharacters] = useState(0);


  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleResumeChange = (event) => {
    const inputString = event.target.value;
    const inputWithoutSpaces = inputString.replace(/\s/g, "").length;
    const tokenCount = inputWithoutSpaces;
    setResumeCharacters(tokenCount);
  };

  const handleQualificationsChange = (event) => {
    const inputString = event.target.value;
    const inputWithoutSpaces = inputString.replace(/\s/g, "").length;
    const tokenCount = inputWithoutSpaces;
    setQualificationsCharacters(tokenCount);
  };



  const incrementLocalCredits = async () => {
    const creditsToken = localStorage.getItem('localcreditstoken142023342');
  const result = increment(creditsToken);
  if(result != 1){
  setLocalCredits(localCredits + 1);
  }
  //window.location.replace("/#/signupform");
  };

  const decrementLocalCredits = async () => {
    const creditsToken = localStorage.getItem('localcreditstoken142023342');
  const result = decrement(creditsToken);
  if(result != 1){
  setLocalCredits(localCredits -1);
  }
  //window.location.replace("/");
  };

  const fetchData = async () => {
    const tempCredits = await retrieveLocalCredits();
    if(tempCredits == -1){
      //create a new token to store local cedits
      storeLocalCredits(-1,0);
      
    }else{
      setLocalCredits(tempCredits);
    };
  };



  useEffect(() => {          
    storeActivity( auth.sessionId,"Page Landing",'CoverLetterBuilder','Page','Landed on CoverLetterBuilder page');
  }, []);

  useEffect(() => {         
    if(auth.user_role_type == "BASIC PLAN" || auth.user_role_type == "ADVANTAGE PLAN" || auth.user_role_type == "ELITE PLAN"  ){
      setIsSubscribed(true);
      }
  }, []);
  
  useEffect(() => { 
    setUsername(localStorage.getItem('username'));
    setCredits(localStorage.getItem('credits'));
    setLoggedIn(localStorage.getItem('loggedIn'));
    setSessionId(localStorage.getItem('sessionId'));
    setEmail(localStorage.getItem('email'));
    setEmailAddress(auth.email);
    ////////console.log(sessionId);
  }, []);
 
  useEffect(() => { 
   // if( auth.loggedIn!=1 ){//not logged in
      //get local credits token and set
      fetchData();
          
 //   };
  }, []);

  useEffect(() => { 
    axios.post('/get-credits', { sessionId: auth.sessionId }, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded',},
       withCredentials: true // This sends the cookie with the request
    })
      .then(response => {
        // set the credit balance to the credits state
        setAuth({
          ...auth,
          credits: response.data.credits, // Update the credits field
        });
      })
      .catch(error => {
        //console.error(error);
      });
    

  }, []);


  function stripSQLInjection(text) {
    text = String(text);
    return text.replace(/'/g, "&apos;")
    .replace(/"/g, "&quot;")
    .replace(/;/g, "&semi;")
    .replace(/-/g, "&dash;")
    .replace(/\+/g, "&plus;");
  }

const [numPage, setNumPages] = useState(null);
const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({numPage}){
    setNumPages(numPage);
    setPageNumber(1);
  }
  
/*   const [localCredits, setLocalCredits] = useState(() => {
    const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)localCredits\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    return cookieValue || 0;
  }); */

  // Update the payment status in a cookie whenever it changes
/* useEffect(() => {
  document.cookie = `localCredits=${localCredits}`;
}, [localCredits]); */

  const sendEmail = async (email, coverLetter) => {
    try {
      await emailjs.send(
        "service_j8ecen9",
        "template_1uenfe9",
        {
          to: email,
          subject: 'Generated Cover Letter',
          message: coverLetter,
        },
        emailjsAPIKEY
      );
    } catch (error) {
      //console.error(error);
    }
  };



  const sendEmailServerSide = async (email, coverLetter,emailType,documentType) => {
    try {
      await axios.post('/send-email', {sessionId:auth.sessionId,email:email,message:coverLetter,emailType:emailType,documentType:documentType},{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true // This sends the cookie with the request
      })
    } catch (error) {
      //console.error(error);
    }
  };

 const generateCoverLetter = async () => {
     if( auth.loggedIn==1 ){//logged in

      if ((localCredits + auth.credits<1) && !isSubscribed ) {
        alert('You are not subscribed.  Please subscribe before generating a premium cover letter.');
        //setTriggerBuyButtonAlert('message','You have no credits.  Please get some credits before generating a premium cover letter.');
        handleOpenModal();
        setTriggerMyAlertMessage(true);
       
       // setTriggerBuyButtonAlert(true);
        return;
      }
     }else{ // not logged in

      alert('Sign up and Login before taking this action');
      window.location.replace("/#/signupform");
     /* if (localCredits<1) {
       alert('You have no credits. Please make a payment before generating a cover letter.');
       return;
     } */
    }

     setIsLoading(true);


     try {
      const uuid = require('uuid');
      const aiSession = uuid.v4();
      let prompt = `Write a cover letter for the postion of "${jobPosition}", here is the posted job qualifications: "${qualifications}" here is my resume: "${resume}"`;

      prompt = prompt.trim();

      const response = await openai.createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages: [{"role": "user", "content": prompt}]
        //temperature: 0.7,
        //max_tokens: 2000,
        //top_p: 1,
        //frequency_penalty: 0,
        //presence_penalty: 0,

     });
      setIsLoading(false);

      const generatedCoverLetter = response.data.choices[0].message.content;



     if(localCredits>=1&& response.status == 200){
    //  const newCreditCount = auth.credits - 1;
      decrementLocalCredits()
     // setLocalCredits(newCreditCount);
     }else{

  if(response.status == 200){
      axios.post('/subtract-credit', {sessionId:auth.sessionId},{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true // This sends the cookie with the request
      })
        .then((response) => {
          ////////console.log(response.status);
          // show success message or update the credit count in the UI
        axios.post('/get-credits', {sessionId:sessionId},{
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true // This sends the cookie with the request
        })
            .then(response => {
              // set the credit balance to the credits state
              setAuth({
                ...auth,
                credits: response.data.credits, // Update the credits field
              });
            })
            .catch(error => {
              //console.error(error);
            });
        })
        .catch((error) => {
          ////////console.log(error);
          // show error message
        }); 

        
      }

    };
     
    
    let jobPositionStore = stripSQLInjection(jobPosition);
    let resumeStore = stripSQLInjection(resume);
    let qualificationsStore = stripSQLInjection(qualifications);
    let emailAddressStore = stripSQLInjection(emailAddress);
    let generatedCoverLetterStore = stripSQLInjection(generatedCoverLetter);

      // Make the POST request to the insert-cover-letter route
      axios.post('/insert-cover-letter', {  sessionId: auth.sessionId,shareCoverLetter:shareCoverLetter,jobPosition:jobPositionStore,resume:resumeStore,qualifications:qualificationsStore,emailAddress:emailAddressStore, letter_text:generatedCoverLetterStore,unstrippedGeneratedCoverLetter:generatedCoverLetter }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true // This sends the cookie with the request
      })
        .then(response => {
          // The cover letter was inserted successfully
        })
        .catch(error => {
          //console.error(error);
        });
      
      setCoverLetter(generatedCoverLetter);
      setDocumentOpen(true);
      sendEmailServerSide(emailAddress, generatedCoverLetter,"document","Cover Letter");
    } catch (error) {
      //console.error(error);
      alert("Error processing: you were not charged.");
     setIsLoading(false);
     try{
     axios.post('/log-error', {  sessionId: auth.sessionId, page:"email generator",errorCode:error }, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withCredentials: true // This sends the cookie with the request
    })
      .then(response => {
        // The cover letter was inserted successfully
      })
      .catch(error => {
        //console.error(error);
      });
    }catch{
      //console.error("error logging error");
    }
    }
  };



  const generateCoverLetterSubscribed  = async () => {
    if( auth.loggedIn==1 ){//logged in

     if ((localCredits + auth.credits<1) && !isSubscribed ) {
       alert('You have no credits.  Please get some credits before generating a premium cover letter.');
       //setTriggerBuyButtonAlert('message','You have no credits.  Please get some credits before generating a premium cover letter.');
       handleOpenModal();
       setTriggerMyAlertMessage(true);
      
      // setTriggerBuyButtonAlert(true);
       return;
     }
    }else{ // not logged in

     alert('Sign up and Login before taking this action');
     window.location.replace("/#/signupform");
    /* if (localCredits<1) {
      alert('You have no credits. Please make a payment before generating a cover letter.');
      return;
    } */
   }

    setIsLoading(true);


    try {
     const uuid = require('uuid');
     const aiSession = uuid.v4();
     let prompt = `Write a cover letter for the postion of "${jobPosition}", here is the posted job qualifications: "${qualifications}" here is my resume: "${resume}"`;

     prompt = prompt.trim();

     const response = await openai.createChatCompletion({
       model: 'gpt-3.5-turbo',
       messages: [{"role": "user", "content": prompt}]
       //temperature: 0.7,
       //max_tokens: 2000,
       //top_p: 1,
       //frequency_penalty: 0,
       //presence_penalty: 0,

    });
     setIsLoading(false);

     const generatedCoverLetter = response.data.choices[0].message.content;


/* 
    if(localCredits>=1&& response.status == 200){
   //  const newCreditCount = auth.credits - 1;
     decrementLocalCredits()
    // setLocalCredits(newCreditCount);
    }else{

 if(response.status == 200){
     axios.post('/subtract-credit', {sessionId:auth.sessionId},{
       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
       withCredentials: true // This sends the cookie with the request
     })
       .then((response) => {
         ////////console.log(response.status);
         // show success message or update the credit count in the UI
       axios.post('/get-credits', {sessionId:sessionId},{
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         withCredentials: true // This sends the cookie with the request
       })
           .then(response => {
             // set the credit balance to the credits state
             setAuth({
               ...auth,
               credits: response.data.credits, // Update the credits field
             });
           })
           .catch(error => {
             //console.error(error);
           });
       })
       .catch((error) => {
         ////////console.log(error);
         // show error message
       }); 

       
     }

   };
     */
   
   let jobPositionStore = stripSQLInjection(jobPosition);
   let resumeStore = stripSQLInjection(resume);
   let qualificationsStore = stripSQLInjection(qualifications);
   let emailAddressStore = stripSQLInjection(emailAddress);
   let generatedCoverLetterStore = stripSQLInjection(generatedCoverLetter);

     // Make the POST request to the insert-cover-letter route
     axios.post('/insert-cover-letter', {  sessionId: auth.sessionId,shareCoverLetter:shareCoverLetter,jobPosition:jobPositionStore,resume:resumeStore,qualifications:qualificationsStore,emailAddress:emailAddressStore, letter_text:generatedCoverLetterStore,unstrippedGeneratedCoverLetter:generatedCoverLetter }, {
       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
       withCredentials: true // This sends the cookie with the request
     })
       .then(response => {
         // The cover letter was inserted successfully
       })
       .catch(error => {
         //console.error(error);
       });
     
     setCoverLetter(generatedCoverLetter);
     setDocumentOpen(true);
     sendEmailServerSide(emailAddress, generatedCoverLetter,"document","Cover Letter");
   } catch (error) {
     //console.error(error);
     alert("Error processing: you were not charged.");
    setIsLoading(false);
    try{
    axios.post('/log-error', {  sessionId: auth.sessionId, page:"email generator",errorCode:error }, {
     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
     withCredentials: true // This sends the cookie with the request
   })
     .then(response => {
       // The cover letter was inserted successfully
     })
     .catch(error => {
       //console.error(error);
     });
   }catch{
     //console.error("error logging error");
   }
   }
 };

 

  return (
    
    <div >
    
      <div style={{ textAlign: 'center' ,padding: '20px',}}>
      
             
        <h1>Cover Letter Genius </h1>
        <p>Enter the information in the boxes below,</p>
        <p> (if you don't want to fill in any of the boxes no worries the AI will do it's best with what it has)</p>
        <h3>Job Position</h3>
        <p>(Enter the job position:)</p>
        <input value={jobPosition} style={{ hight:'25',width: '200px', maxWidth: '100%' }} onChange={(e) => setJobPosition(e.target.value)} />
        <br />
        <br />
        <h3>Resume</h3>
        <p>(Copy and paste your resume:)</p>
        {resumeCharacters > resumeCharacterLimit ? (
        <p style={{ color: "red" }} className="mx-auto text-center">Character limit exceeded! {resumeCharacters} / {resumeCharacterLimit}</p>
      ) : (
        <p className="mx-auto text-center">Non-empty resumeCharacters remaining: {resumeCharacterLimit - resumeCharacters}</p>
      )}
        <div className="d-flex justify-content-center">
       <textarea  value={resume} style={{ 
    height:'100px',
    width: '500px', 
    maxHeight: '50%', 
    maxWidth: '100%', 
    border: resumeCharacters > resumeCharacterLimit ? '4px solid red' : '1px solid gray',
    color: resumeCharacters > resumeCharacterLimit ? 'red' : 'black', 
  }}  onChange={(e) => {
    setResume(e.target.value);
    handleResumeChange(e);
  }}  />
  </div>
        {/* <textarea value={resume} style={{ height: '100px', width: '500px', maxWidth: '100%' }} onChange={(e) => setResume(e.target.value)} /> */}
        <br />
        <br />
        <h3>Job Qualifications</h3>
        <p>(Copy and paste the job qualifications:)</p>
        {qualificationsCharacters > resumeCharacterLimit ? (
        <p style={{ color: "red" }} className="mx-auto text-center">Character limit exceeded! {qualificationsCharacters} / {qualificationsCharacterLimit}</p>
      ) : (
        <p className="mx-auto text-center">Non-empty Job Qualifications Characters remaining: {qualificationsCharacterLimit - qualificationsCharacters}</p>
      )}
        <div className="d-flex justify-content-center">
       <textarea  value={qualifications} style={{ 
    height:'100px',
    width: '500px', 
    maxHeight: '50%', 
    maxWidth: '100%', 
    border: qualificationsCharacters > qualificationsCharacterLimit ? '4px solid red' : '1px solid gray',
    color: qualificationsCharacters > qualificationsCharacterLimit ? 'red' : 'black', 
  }}  onChange={(e) => {
    setQualifications(e.target.value);
    handleQualificationsChange(e);
  }}  />
  </div>
        {/* <textarea value={qualifications} style={{   width: '500px' , maxWidth: '100%' }} onChange={(e) => setQualifications(e.target.value)} /> */}
        <br />
        <br />
        <h3>Email Address</h3>
         <p>(Enter your email address:)</p>
        <input value={ emailAddress} style={{ hight:'25',width: '200px' , maxWidth: '100%' }} onChange={(e) => setEmailAddress(e.target.value)} /> 
        <br />
        <br />
     {/*    <Container> */}
    <div className="d-flex justify-content-center">

        <form>
            <p className="mx-auto text-center">Opt in to our AI career network below</p>
            <input type="checkbox" checked={shareCoverLetter}  value={shareCoverLetter} style={{margin:"10px"}} onChange={() => setShareCoverLetter(!shareCoverLetter)} />       
            <label> Connected me to recruiters and companies.</label>
        
            {/* <button type="submit" onClick={handleSubmit}>Submit</button> */}
        </form>
    </div>
{/*     </Container> */}
    <br/>
  <div style={{ textAlign: 'center' }}>
  <p style={{ fontWeight: 'bold', textDecoration: 'underline',color: '#004e84' }}>Instructions</p>
    <ol style={{ listStylePosition: 'inside' }}>
      <li>After filling in the boxes above, press the "Generate Cover Letter" button.</li>
      <li>Wait a moment and your cover letter will display on the page as well as be sent to your email.</li>
      {/* <li>Read over the cover letter and fix any errors before submitting it.</li> */}
  </ol>
  </div>

  { isSubscribed ? (isLoading ? ( <div><FaSpinner icon="spinner" className="spinner" /> Loading...</div> ) : (<button className="btn btn-primary" onClick={generateCoverLetterSubscribed }>Generate AI Optimized Cover Letter</button> )) : (isLoading ? ( <div><FaSpinner icon="spinner" className="spinner" /> Loading...</div> ) : (<button className="btn btn-primary" onClick={generateCoverLetter}>Generate AI Optimized Cover Letter ( Member Only ) </button> ))}
  
{/*   {auth.loggedIn==1 ?(localCredits>=1 ? <div style={{ color:'#257883' }}>
    Account Credits: {auth.credits } + non account credits:{localCredits}
  </div> :  <div style={{ color:'#257883' }}> Account Credits: {auth.credits } </div> ) : <div style={{ color:'#b73133' }}>
    Credits: {localCredits}
  </div>} */}
      {isSubscribed ? <div style={{ color:'#257883' }}>
    Member Account
  </div>  : (auth.loggedIn==1 ? (localCredits>=1 ? <div style={{ color:'#257883' }}>
    Account Credits: {auth.credits } + non account credits:{localCredits}
  </div> :  <div style={{ color:'#257883' }}> Account Credits: {auth.credits } </div>  ) : <div style={{ color:'#b73133' }}>
    Credits: {localCredits}
  </div> ) } 
  {documentOpen ? <MyAlertDocument documentOpen={documentOpen}  setDocumentOpen={setDocumentOpen} handleDocumentCloseModal={handleDocumentCloseModal} documentFile={coverLetter}/> : null}
  {coverLetter && <div style={{ justifyContent: 'center',backgroundColor: '#fafafa', fontStyle: 'italic',whiteSpace: 'pre-wrap', textIndent: '50px' }}>{coverLetter}</div>}
  {/* <MyAlert trigger={triggerBuyButtonAlert}/> */}
  {open ? <MyAlertMessage open={open}  setOpen={setOpen} handleClose={handleCloseModal}/> : null}
{/*    <div>
    <Document file="./JosephPhillipsResume.pdf" onDocumentLoadSuccess={onDocumentLoadSuccess}>
      <Page height="1000" pageNumber={pageNumber}></Page>   
    </Document>
  </div>  */}

      <br></br>
      <br></br>
      {/* {auth.loggedIn==1 ? null :
      <PayPalScriptProvider
        options={{ "client-id": payPalClientId }}
      >
        <div style={{ justifyContent: 'center' }}>
        <PayPalButtons 
        style={{
          layout: 'horizontal',
          color: 'blue',
          shape: 'rect',
          label: 'pay',
          size: 'medium',
        }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: "0.01",
                  },
                },
              ],
            });
          }}
          onApprove={async (data, actions) => {
            const details = await actions.order.capture();
            const name = details.payer.name.given_name;
            ////////console.log('Payment success', details);
            //setLocalCredits(localCredits + 1);
            incrementLocalCredits();
          }}
        />
        </div>
      </PayPalScriptProvider>
      } */}
      {
        auth.user_role_type == "tester" ?
      <div>
       <button variant="primary" onClick={() => incrementLocalCredits()}>
      Increment Credits
     </button>
     <button variant="primary" onClick={() => decrementLocalCredits()}>
      decrement Credits
     </button> 
     </div>:null

      }
      </div>

      
     
  </div>
  
  );
  };
  
  export default CoverLetterBuilder;    
