import React ,{useState,useMemo} from 'react';
import { withRouter ,HashRouter,BrowserRouter, Routes, Route  } from "react-router-dom";
import { Router, Route as ReactRoute, browserHistory } from 'react-router'
import ReactDOM from 'react-dom/client';
import './index.css';

import Header from './Header.jsx';
import About from './About';
import TermsAndConditions from './TermsAndConditions.jsx';
import Contact from './Contact';
import Footer from './Footer';
import BuyCredits from './BuyCredits.jsx';
import Subscribe from './Subscribe.jsx';
import SubscribeFreeTrial from './SubscribeFreeTrial.jsx';
import AccountPage from './AccountPage.jsx';

import SignupForm from './SignupForm.jsx';
import LoginForm from './LoginForm.jsx';
import RequestResetPassword from './RequestResetPassword.jsx';
import ForgotUsername from './ForgotUsername.jsx';
import ResetPassword from './ResetPassword.jsx';
import ResumeBuilder from './Resume/ResumeBuilder.jsx';
import ResumeExperienceBuilder from './ResumeExperience/ResumeBuilder.jsx';
import InterviewPrep from './InterviewPrep/InterviewPrep'; // Adjust the path if necessary
import EmailBuilder from './Email/EmailBuilder.jsx';
import SocialPostBuilder from './SocialPost/SocialPostBuilder.jsx';
import Home from './Home.jsx'
import ResumeTools from './ResumeTools.jsx'
import CoverLetterBuilder from './CoverLetterBuilder.jsx'
import { AuthProvider } from './context/AuthProvider.js';
import { UserContext } from './context/UserContext';
import ReactGA from 'react-ga';
import AppleDeveloperMerchantIdDomainAssociation from './AppleDeveloperMerchantIdDomainAssociation.js'


// seo page imports
import { ResumeHelp } from './seowebpages/ResumeHelp.js';
import { CoverLetterHelp  } from './seowebpages/CoverLetterHelp.js';
import { ResumeWritingServices  } from './seowebpages/ResumeWritingServices.js';
import { ProfessionalResumeWriters  } from './seowebpages/ProfessionalResumeWriters.js';
import { CoverLetterWritingTips  } from './seowebpages/CoverLetterWritingTips.js';
import { HowToWriteAStrongResume  } from './seowebpages/HowToWriteAStrongResume.js';
import { EffectiveCoverLetterWriting  } from './seowebpages/EffectiveCoverLetterWriting.js';
import { BestPracticesForResumeAndCoverLetterWriting  } from './seowebpages/BestPracticesForResumeAndCoverLetterWriting.js';
import { ResumeCritique  } from './seowebpages/ResumeCritique.js';
import { ResumeExample2023  } from './seowebpages/ResumeExample2023.js';

const App = () => {
  const [globalUser,setGlobalUser] = useState([null,0,false]); //username,credits,logged-in

  const providerValue = useMemo(()=>({globalUser,setGlobalUser}),[globalUser,setGlobalUser]) 

  const TRACKING_ID = "G-RWFVQZ7BJS"; // OUR_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);

return(

  <UserContext.Provider value={{globalUser,setGlobalUser}}>
  <AuthProvider>
  <BrowserRouter basename="/">
      <Routes>
      <Route exact path="/" element={<div><Header/><Home /><Footer /></div>}/>
      <Route  path="/*" element={<div><Header/><Home /><Footer /></div>}/>
        <Route index element={<div><Header/><Home /><Footer /></div>} />
        <Route path="ResumeTools" element={<div><Header/><ResumeTools /><Footer /></div>} />
        <Route path="CoverLetterBuilder" element={<div><Header/><CoverLetterBuilder /><Footer /></div>} />
        <Route path="about" element={<div><Header/><About /><Footer /></div>} />
        <Route path="termsandconditions" element={<div><Header/><TermsAndConditions /><Footer /></div>} />
        <Route path="Contact" element={<div><Header/><Contact /><Footer /></div>} />
        <Route path="SignupForm" element={<div><Header/><SignupForm /></div>} />
        <Route path="LoginForm" element={<div><Header/><LoginForm /></div>} />
        <Route path="RequestResetPassword" element={<div><Header/><RequestResetPassword /></div>} />
        <Route path="ForgotUsername" element={<div><Header/><ForgotUsername /></div>} />
        {/* <Route path="ResetPassword" element={<div><Header/><ResetPassword /></div>} /> */}
        <Route path="ResetPassword/:token" element={<div><Header /><ResetPassword /></div>} />
        <Route path="BuyCredits" element={<div><Header/><BuyCredits /><Footer /></div>} />
        <Route path="Subscribe" element={<div><Header/><Subscribe /><Footer /></div>} />
        <Route path="SubscribeFreeTrial" element={<div><Header/><SubscribeFreeTrial /><Footer /></div>} />
        <Route path="Account" element={<div><Header/><AccountPage /><Footer /></div>} />
        <Route path="ResumeBuilder" element={<div><Header/><ResumeBuilder /><Footer /></div>} />
        <Route path="ResumeExperienceBuilder" element={<div><Header/><ResumeExperienceBuilder /><Footer /></div>} />
        <Route path="InterviewPrep" element={<div><Header/><InterviewPrep /><Footer /></div>} />
        <Route path="EmailBuilder" element={<div><Header/><EmailBuilder /><Footer /></div>} />
        <Route path="socialpostbuilder" element={<div><Header/><SocialPostBuilder /><Footer /></div>} />


      {/* seo pages */}
      <Route path="resumehelp" element={<div><Header/><ResumeHelp /><Footer /></div>} />
      <Route path="CoverLetterHelp" element={<div><Header/><CoverLetterHelp /><Footer /></div>} />
      <Route path="ResumeWritingServices" element={<div><Header/><ResumeWritingServices /><Footer /></div>} />
      <Route path="ProfessionalResumeWriters" element={<div><Header/><ProfessionalResumeWriters /><Footer /></div>} />
      <Route path="CoverLetterWritingTips" element={<div><Header/><CoverLetterWritingTips /><Footer /></div>} />
      <Route path="HowToWriteAStrongResume" element={<div><Header/><HowToWriteAStrongResume /><Footer /></div>} />
      <Route path="EffectiveCoverLetterWriting" element={<div><Header/><EffectiveCoverLetterWriting /><Footer /></div>} />
      
    <Route path="BestPracticesForResumeAndCoverLetterWriting" element={<div><Header/><BestPracticesForResumeAndCoverLetterWriting /><Footer /></div>} /> 
    <Route path="ResumeCritique" element={<div><Header/><ResumeCritique /><Footer /></div>} /> 
    <Route path="resumeexample2023" element={<div><Header/><ResumeExample2023 /><Footer /></div>} /> 
   {/*  <Route
        path="/.well-known/apple-developer-merchantid-domain-association.txt"
        element={<AppleDeveloperMerchantIdDomainAssociation/>}
      /> */}

      </Routes>
    </BrowserRouter>
  </AuthProvider>
</UserContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
};
export default App