import axios from 'axios';
import React from 'react';

// Create an instance of axios with a predefined base URL
const api = axios.create({
    // baseURL: 'http://localhost:5000'
    //baseURL: 'https://coverlettergeneratorai.com'
    baseURL: 'https://careergenius.zapto.org'
    // baseURL: 'http://coverletternode.onrender.com'
});

// Function to show alert only once
function showAlertOnce(message) {
    if (!window._hasAlerted) {
        window._hasAlerted = true;
        alert(message);
        setTimeout(() => window._hasAlerted = false, 1000); // Prevent alerts for 1 second
    }
}

// Function to handle logout and prevent multiple alerts
function handleLogout() {
    if (!sessionStorage.getItem('isLoggedOut')) {
        sessionStorage.setItem('isLoggedOut', 'true');
        showAlertOnce("You have been logged out due to inactivity or session expiry.");
        window.location.replace('/login');
    }
}

// Add a response interceptor to handle 401 Unauthorized globally
const interceptor = api.interceptors.response.use(
    response => response, // Return the response as is if successful
    error => {
        if (error.response && error.response.status === 401) {
            // If the server returns a 401 status code, handle logout
            handleLogout();

            // Remove the interceptor after handling the logout to prevent further execution
            api.interceptors.response.eject(interceptor);
        }
        return Promise.reject(error); // Return the error to be handled further down the chain if needed
    }
);

export default api;
